export enum FbcPosUserStatus {
  active = "active",
  deleted = "deleted",
}

export interface FbcPosUser {
  username: string;
  display_name?: string;
  status: FbcPosUserStatus;
}

export interface FbcPosUserCreateRequest {
  username: string;
  display_name?: string;
  password: string;
}
