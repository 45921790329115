import axios from "axios";
import { Dispatch } from "redux";
import { FbcPosUser } from "../model/fbc/fbc.pos.user";
import { ActionType } from "../model/actions";
import { mapErrorFromException } from "../model/error";

export const getUsers =
  ({ company_id, completion }: { company_id: string; completion: (users: FbcPosUser[]) => void }) =>
  async (dispatch: Dispatch) => {
    try {
      const res = await axios.get(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club-users`);
      completion(res.data);
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const createUser =
  ({
    company_id,
    username,
    display_name,
    password,
    completion,
  }: {
    company_id: string;
    username: string;
    display_name: string;
    password: string;
    completion: () => void;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      await axios.post(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club-users`, {
        username,
        display_name,
        password,
      });
      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const updateUserDisplayName =
  ({
    company_id,
    username,
    display_name,
    completion,
  }: {
    company_id: string;
    username: string;
    display_name: string;
    completion: () => void;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      await axios.put(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club-users`, {
        username,
        display_name,
      });
      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const updateUserPassword =
  ({
    company_id,
    username,
    password,
    completion,
  }: {
    company_id: string;
    username: string;
    password: string;
    completion: () => void;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      await axios.put(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club-users/password`, {
        username,
        password,
      });
      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };

export const deleteUser =
  ({ company_id, username, completion }: { company_id: string; username: string; completion: () => void }) =>
  async (dispatch: Dispatch) => {
    try {
      await axios.delete(`/v2/api/b2b/private/companies/${company_id}/family-bonus-club-users/${username}`);
      completion();
    } catch (error) {
      dispatch({
        type: ActionType.PAGE_SAVING_ERROR,
        payload: { error: mapErrorFromException(error) },
      });
    }
  };
