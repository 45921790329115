import { useRequestPreSend } from "@rpldy/uploady";

export default function MimeTypeInterceptor({ acceptedTypes }: { acceptedTypes: string[] }) {
  useRequestPreSend((data) => {
    let valid = true;

    data.items.forEach((item) => {
      if (acceptedTypes.indexOf(item.file.type) < 0) {
        valid = false;
      }
    });

    return valid;
  });

  return <></>;
}
