import { FbcOfferData, FbcOfferRedemptionType } from "../../model/fbc/fbc.offer";
import AppStyles from "../../styles/appStyles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { FormGridItem } from "../components/forms/event_edit/FormGridItem";
import * as React from "react";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import intl from "react-intl-universal";
import { asNonEmptyString, isNonEmptyNumber } from "../../util/util_extensions";
import { SectionLabel } from "../../components/SectionLabel";
import moment from "moment/moment";
import { DefaultButton } from "../../components/Buttons";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FamilyBonusClubValidityPeriodEdit from "./FamilyBonusClubValidityPeriodEdit";
import { NumericFormat } from "react-number-format";

export interface Props {
  offerData: FbcOfferData;
  onChangeOfferData?: (data: FbcOfferData) => void;
  readonly: boolean;
  children?: any;
  maxUsagesUnlimited: boolean;
  maxUsagesPerDayUnlimited: boolean;
  onSetMaxUsagesUnlimited?: (boolean) => void;
  onSetMaxUsagesPerDayUnlimited?: (boolean) => void;
  markFieldsAsChanged?: string[];
}

export function FamilyBonusClubOfferForm(props: Props) {
  const renderTitle = () => {
    return (
      <TextFieldWithLabel
        type={props.offerData.title}
        required={!props.readonly && true}
        label={intl.get("family_bonus_club.offer_form.labels.title")}
        id={"title"}
        margin="normal"
        disabled={props.readonly}
        color={"secondary"}
        error={props.markFieldsAsChanged?.includes("title")}
        onChange={(event) => {
          props.onChangeOfferData({ ...props.offerData, title: event.target.value });
        }}
        value={props.offerData.title}
      />
    );
  };

  const renderDescription = () => {
    return (
      <TextFieldWithLabel
        multiline
        rows={10}
        maxLength={1500}
        type={props.offerData.description}
        required={!props.readonly && true}
        label={intl.get("family_bonus_club.offer_form.labels.description")}
        id={"description"}
        margin="normal"
        disabled={props.readonly}
        error={props.markFieldsAsChanged?.includes("description")}
        onChange={(event) => {
          props.onChangeOfferData({ ...props.offerData, description: event.target.value });
        }}
        value={props.offerData.description}
      />
    );
  };

  const renderMaxUsagesPerCard = () => {
    return (
      <TextFieldWithLabel
        type="number"
        label={intl.get(`family_bonus_club.offer_form.labels.max_usages_per_card`)}
        id={"max_usages_per_card"}
        margin="normal"
        disabled={props.readonly || props.maxUsagesUnlimited}
        onChange={(event) => {
          if (
            asNonEmptyString(event.target.value) &&
            isNonEmptyNumber(+event.target.value) &&
            Number(event.target.value) > 0
          ) {
            props.onChangeOfferData({ ...props.offerData, max_usages_per_card: Number(event.target.value) });
          } else {
            props.onChangeOfferData({ ...props.offerData, max_usages_per_card: undefined });
          }
        }}
        value={props.offerData.max_usages_per_card ?? ""}
      />
    );
  };

  const renderMaxUsagesPerCardPerDay = () => {
    if (props.offerData.offer_redemption_type === FbcOfferRedemptionType.online) {
      return null;
    }
    return (
      <TextFieldWithLabel
        type="number"
        label={intl.get(`family_bonus_club.offer_form.labels.max_usages_per_card_per_day`)}
        id={"max_usages_per_card_per_day"}
        margin="normal"
        disabled={props.readonly || props.maxUsagesPerDayUnlimited}
        onChange={(event) => {
          if (
            asNonEmptyString(event.target.value) &&
            isNonEmptyNumber(+event.target.value) &&
            Number(event.target.value) > 0
          ) {
            props.onChangeOfferData({ ...props.offerData, max_usages_per_card_per_day: Number(event.target.value) });
          } else {
            props.onChangeOfferData({ ...props.offerData, max_usages_per_card_per_day: undefined });
          }
        }}
        value={props.offerData.max_usages_per_card_per_day ?? ""}
      />
    );
  };

  const renderMaxUsagesPerCardUnlimitedCheckbox = () => {
    return (
      <Grid item container direction="column">
        <SectionLabel required={false}></SectionLabel>

        <Grid item>
          <FormControlLabel
            sx={AppStyles.detailFormControlLabel}
            disabled={props.readonly}
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={props.maxUsagesUnlimited}
                onChange={(event) => {
                  props.onSetMaxUsagesUnlimited(event.target.checked);
                }}
              />
            }
            label={intl.get(`family_bonus_club.offer_form.labels.max_usages_per_card_unlimited`)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderMaxUsagesPerCardPerDayUnlimitedCheckbox = () => {
    if (props.offerData.offer_redemption_type === FbcOfferRedemptionType.online) {
      return null;
    }
    return (
      <Grid item container direction="column">
        <SectionLabel required={false}></SectionLabel>

        <Grid item>
          <FormControlLabel
            sx={AppStyles.detailFormControlLabel}
            disabled={props.readonly}
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={props.maxUsagesPerDayUnlimited}
                onChange={(event) => {
                  props.onSetMaxUsagesPerDayUnlimited(event.target.checked);
                }}
              />
            }
            label={intl.get(`family_bonus_club.offer_form.labels.max_usages_per_card_per_day_unlimited`)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderSavings = () => {
    return (
      <Grid item container direction="column">
        <SectionLabel required={true}>{intl.get("family_bonus_club.offer_form.labels.savings_in_cents")}</SectionLabel>
        <Grid item container direction="row">
          <NumericFormat
            value={props.offerData.savings_in_cents}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={TextField}
            disabled={props.readonly}
            variant="outlined"
            style={{ width: "100%" }}
            onValueChange={(values) => {
              const { value } = values;

              if (value && value.length > 0) {
                props.onChangeOfferData({ ...props.offerData, savings_in_cents: Number(value) });
              } else {
                props.onChangeOfferData({ ...props.offerData, savings_in_cents: undefined });
              }
            }}
          />
        </Grid>
      </Grid>
    );
  };

  const renderOnlineShopURL = () => {
    return (
      <TextFieldWithLabel
        type={props.offerData.online_offer_shop_url}
        required={!props.readonly && true}
        label={intl.get("family_bonus_club.offer_form.labels.online_offer_shop_url")}
        id={"online_offer_shop_url"}
        margin="normal"
        disabled={props.readonly}
        color={"secondary"}
        error={props.markFieldsAsChanged?.includes("online_offer_shop_url")}
        onChange={(event) => {
          props.onChangeOfferData({ ...props.offerData, online_offer_shop_url: event.target.value });
        }}
        value={props.offerData.online_offer_shop_url}
      />
    );
  };

  const validityPeriodMap = props.offerData.validity_periods.map((value, index) => {
    return (
      <TableRow key={`validity_period_${index}`}>
        <FamilyBonusClubValidityPeriodEdit
          disabled={props.readonly}
          validity_period={value}
          onRemove={() => {
            const updatedOfferData = { ...props.offerData };
            updatedOfferData.validity_periods.splice(index, 1);
            props.onChangeOfferData(updatedOfferData);
          }}
          onChange={(newValue) => {
            const updatedOfferData = { ...props.offerData };
            updatedOfferData.validity_periods[index] = newValue;
            props.onChangeOfferData(updatedOfferData);
          }}
        />
      </TableRow>
    );
  });

  const renderOfferRedemptionType = () => {
    return (
      <Grid item container direction="column" style={{ marginTop: "8px" }}>
        <SectionLabel
          required={props.markFieldsAsChanged?.includes("offer_redemption_type") ? undefined : true}
          error={props.markFieldsAsChanged?.includes("offer_redemption_type")}
        >
          {intl.get("family_bonus_club.offer_form.labels.offer_redemption_type")}
        </SectionLabel>
        <Grid item container direction="row">
          <RadioGroup
            color="primary"
            aria-label="Type"
            name="type"
            onChange={(event) => {
              props.onChangeOfferData({
                ...props.offerData,
                offer_redemption_type: event.target.value,
              });
            }}
            row
          >
            <FormControlLabel
              key={FbcOfferRedemptionType.offline}
              value={FbcOfferRedemptionType.offline}
              control={
                <Radio
                  color="primary"
                  disabled={props.readonly}
                  checked={props.offerData.offer_redemption_type === FbcOfferRedemptionType.offline}
                />
              }
              label={intl.get("family_bonus_club.offer_form.labels.offer_redemption_type_offline")}
            />
            <FormControlLabel
              key={FbcOfferRedemptionType.mixed}
              value={FbcOfferRedemptionType.mixed}
              control={
                <Radio
                  color="primary"
                  disabled={props.readonly}
                  checked={props.offerData.offer_redemption_type === FbcOfferRedemptionType.mixed}
                />
              }
              label={intl.get("family_bonus_club.offer_form.labels.offer_redemption_type_mixed")}
            />
            <FormControlLabel
              key={FbcOfferRedemptionType.online}
              value={FbcOfferRedemptionType.online}
              control={
                <Radio
                  color="primary"
                  disabled={props.readonly}
                  checked={props.offerData.offer_redemption_type === FbcOfferRedemptionType.online}
                />
              }
              label={intl.get("family_bonus_club.offer_form.labels.offer_redemption_type_online")}
            />
          </RadioGroup>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box sx={AppStyles.innerContentRootWithoutLimit}>
      {props.children}

      <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
        <Typography>
          <b>{intl.get(`family_bonus_club.offer_form.sections.offer_info`)}</b>
        </Typography>
      </Grid>
      <Paper sx={AppStyles.innerContentPaper}>
        <Grid item container direction="row" sx={AppStyles.detailForm}>
          <FormGridItem md={12} smallPadding>
            {renderTitle()}
          </FormGridItem>
          <FormGridItem md={12} smallPadding>
            {renderDescription()}
          </FormGridItem>
          <FormGridItem md={12} smallPadding>
            {renderSavings()}
          </FormGridItem>
          <FormGridItem md={12} smallPadding>
            {renderOfferRedemptionType()}
          </FormGridItem>
        </Grid>
      </Paper>

      {(props.offerData.offer_redemption_type === FbcOfferRedemptionType.online ||
        props.offerData.offer_redemption_type === FbcOfferRedemptionType.mixed) && (
        <>
          <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
            <Typography>
              <b>{intl.get(`family_bonus_club.offer_form.sections.online_offer_shop`)}</b>
            </Typography>
          </Grid>
          <Paper sx={AppStyles.innerContentPaper}>
            <Grid item container direction="row" sx={AppStyles.detailForm}>
              <FormGridItem md={12} smallPadding>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: intl.get(`family_bonus_club.offer_form.hint_online_offer_shop_url`),
                  }}
                />
              </FormGridItem>

              <FormGridItem md={12} smallPadding>
                {renderOnlineShopURL()}
              </FormGridItem>
            </Grid>
          </Paper>
        </>
      )}

      <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
        <Typography
          color={
            props.markFieldsAsChanged?.includes("max_usages_per_card") ||
            props.markFieldsAsChanged?.includes("max_usages_per_card_per_day")
              ? "error"
              : undefined
          }
        >
          <b>{intl.get(`family_bonus_club.offer_form.sections.contingent`)}</b>
        </Typography>
      </Grid>

      <Paper sx={AppStyles.innerContentPaper}>
        <Grid item container direction="row" sx={AppStyles.detailForm}>
          <FormGridItem md={6} smallPadding>
            {renderMaxUsagesPerCardUnlimitedCheckbox()}
          </FormGridItem>
          <FormGridItem md={6} smallPadding>
            {renderMaxUsagesPerCard()}
          </FormGridItem>
          <FormGridItem md={6} smallPadding>
            {renderMaxUsagesPerCardPerDayUnlimitedCheckbox()}
          </FormGridItem>
          <FormGridItem md={6} smallPadding>
            {renderMaxUsagesPerCardPerDay()}
          </FormGridItem>
        </Grid>
      </Paper>

      <Grid item container direction="row" sx={AppStyles.detailFormWithoutBottomPadding}>
        <Typography color={props.markFieldsAsChanged?.includes("validity_period") ? "error" : undefined}>
          <b>{intl.get(`family_bonus_club.offer_form.sections.validity`)}</b>
        </Typography>
      </Grid>
      <Paper sx={AppStyles.innerContentPaper}>
        {validityPeriodMap.length > 0 && (
          <Grid item container direction="row" sx={AppStyles.detailForm}>
            <Table>
              <TableBody>{validityPeriodMap}</TableBody>
            </Table>
          </Grid>
        )}
        <Grid container direction="column" sx={AppStyles.detailForm}>
          <DefaultButton
            onClick={(event: any) => {
              event.stopPropagation();

              const currentYear = moment(new Date()).locale("de").format("yyyy");
              const rangeStart = moment(`01.01.${currentYear}`, "DD.MM.yyyy");
              const rangeEnd = moment(`31.12.${currentYear}`, "DD.MM.yyyy");

              props.onChangeOfferData({
                ...props.offerData,
                validity_periods: [
                  ...props.offerData.validity_periods,
                  {
                    from: rangeStart.locale("de").format("YYYY-MM-DD"),
                    until: rangeEnd.locale("de").format("YYYY-MM-DD"),
                    days: "mon,tue,wed,thu,fri,sat,sun,hol",
                  },
                ],
              });
            }}
          >
            <DateRangeIcon sx={AppStyles.buttonLeftIcon} />
            {validityPeriodMap.length === 0
              ? intl.get("family_bonus_club.offer_form.labels.add_validity_period")
              : intl.get("family_bonus_club.offer_form.labels.add_additional_validity_period")}
          </DefaultButton>
        </Grid>
      </Paper>
    </Box>
  );
}
