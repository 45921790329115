import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { PaymentState } from "../model/paymentState";

export const paymentState = createReducer(
  {
    invoice_paging: {
      page_size: 10,
      current_page: 1,
    },
    allInvoices: [],
    paymentInfo: null,
    closePaymentMethodPage: false,
    reloadInvoices: false,
    activeProducts: [],
    credits: [],
    availableProducts: [],
    status: null,
  },
  {
    [ActionType.FETCH_COMPANY_INVOICES](state: PaymentState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          reloadInvoices: false,
          allInvoices: action.payload.invoices,
          invoice_paging: action.payload.paging,
          closePaymentMethodPage: false,
        };
      }
      return state;
    },
    [ActionType.FETCH_COMPANY_PAYMENT_METHOD](state: PaymentState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          paymentInfo: action.payload.payment_info,
          closePaymentMethodPage: false,
        };
      }
      return state;
    },
    [ActionType.UPDATE_COMPANY_PAYMENT_METHOD](state: PaymentState, action: Action<any>) {
      return {
        ...state,
        paymentInfo: action.payload,
        closePaymentMethodPage: true,
        status: action.payload ? null : state.status,
      };
    },
    [ActionType.CLOSE_COMPANY]() {
      return {
        invoice_paging: {
          page_size: 10,
          current_page: 1,
        },
        allInvoices: [],
        paymentInfo: null,
        activeProducts: [],
        credits: [],
        availableProducts: [],
        closePaymentMethodPage: false,
      };
    },
    [ActionType.FETCH_PAYMENT_PRODUCT_INFORMATION](state: PaymentState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          activeProducts: action.payload.activeProducts,
          credits: action.payload.credits,
          availableProducts: action.payload.availableProducts,
          status: action.payload.status,
        };
      } else if (action.payload.status) {
        return {
          ...state,
          status: action.payload.status,
        };
      }
      return state;
    },
    [ActionType.FETCH_AVAILABLE_PAYMENT_PRODUCTS](state: PaymentState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          availableProducts: action.payload.availableProducts,
        };
      }
      return state;
    },
    [ActionType.CHARGE_COMPANY_OVERDUE_INVOICES](state: PaymentState) {
      return {
        ...state,
        reloadInvoices: true,
      };
    },
  },
);
