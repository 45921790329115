import { CompanyState } from "../../model/companyState";
import * as FamilyBonusClubUserActions from "../../actions/family.bonus.club.users.actions";
import { connect, useDispatch } from "react-redux";
import useSelectedCompany from "../../hooks/useSelectedCompany";
import { RootState } from "../../reducers";
import { compose } from "redux";
import * as React from "react";
import { useEffect, useState } from "react";
import { FbcPosUser, FbcPosUserCreateRequest, FbcPosUserStatus } from "../../model/fbc/fbc.pos.user";
import useDialogHandler from "../../components/dialog/useDialogHandler";
import { PageTracker } from "../../util/pageTracker";
import {
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AppStyles from "../../styles/appStyles";
import { HeaderLabel } from "../../components/header/HeaderLabel";
import intl from "react-intl-universal";
import { DefaultButton, LinkButton } from "../../components/Buttons";
import DialogDeleteFamilyBonusClubPosUserConfirmation from "./DialogDeleteFamilyBonusClubPosUserConfirmation";
import AddIcon from "@mui/icons-material/Add";
import DialogCreateFamilyBonusClubPosUser from "./DialogCreateFamilyBonusClubPosUser";
import DialogUpdateFamilyBonusPosUserDisplayName from "./DialogUpdateFamilyBonusPosUserDisplayName";
import DialogUpdateFamilyBonusPosUserPassword from "./DialogUpdateFamilyBonusClubPosUserPassword";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import { asNonEmptyString } from "../../util/util_extensions";
import axios from "axios";
import CallToActionHeaderBanner, { OnboardingImageType } from "../../components/events/CallToActionHeaderBanner";
import DialogJoinFamilyBonusClub from "../fbc/DialogJoinFamilyBonusClub";
import { showSuccessMessage } from "../../actions/errorDialog";

export interface Props {
  companyState: CompanyState;
}

function FamilyBonusClubUsersPage() {
  const dispatch = useDispatch();
  const company = useSelectedCompany();

  const [users, setUsers] = useState<FbcPosUser[]>([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = () => {
    dispatch<any>(
      FamilyBonusClubUserActions.getUsers({
        company_id: company.company_id,
        completion: (loadedUsers) => {
          setUsers(loadedUsers);
        },
      }),
    );
  };

  const deleteUserHandler = useDialogHandler<FbcPosUser>("deleteFbcPosUser");
  const newUserDialogHandler = useDialogHandler<void>("createNewFbcPosUserDialog");
  const updateUserDisplayNameDialogHandler = useDialogHandler<FbcPosUser>("updateFbcPosUserDisplayNameDialog");
  const updateUserPasswordDialogHandler = useDialogHandler<FbcPosUser>("updateFbcPosUserPasswordDialog");

  const showFamilyBonusClubOnboarding = company.family_bonuscard.has_joined_fbc === false;
  const joinFamilyBonusClubDialogHandler = useDialogHandler<void>("joinFamilyBonusClubDialog");

  function joinFamilyBonusClub() {
    joinFamilyBonusClubDialogHandler.open();
  }

  function createNewUser() {
    newUserDialogHandler.open();
  }

  async function createNewFamilyBonusClubPosUser({ request }: { request: FbcPosUserCreateRequest }) {
    dispatch<any>(
      FamilyBonusClubUserActions.createUser({
        company_id: company.company_id,
        username: request.username,
        display_name: request.display_name,
        password: request.password,
        completion: () => {
          newUserDialogHandler.close();
          loadUsers();
          dispatch<any>(showSuccessMessage(intl.get("family_bonus_club.pos_users.success_create")));
        },
      }),
    );
  }

  async function deleteFamilyBonusClubPosUser({ username }: { username: string }) {
    dispatch<any>(
      FamilyBonusClubUserActions.deleteUser({
        company_id: company.company_id,
        username,
        completion: () => {
          deleteUserHandler.close();
          loadUsers();
          dispatch<any>(showSuccessMessage(intl.get("family_bonus_club.pos_users.success_delete")));
        },
      }),
    );
  }

  async function updateFamilyBonusClubPosUserDisplayName({
    username,
    display_name,
  }: {
    username: string;
    display_name: string;
  }) {
    dispatch<any>(
      FamilyBonusClubUserActions.updateUserDisplayName({
        company_id: company.company_id,
        username,
        display_name,
        completion: () => {
          updateUserDisplayNameDialogHandler.close();
          loadUsers();
          dispatch<any>(showSuccessMessage(intl.get("family_bonus_club.pos_users.success_update_display_name")));
        },
      }),
    );
  }

  async function updateFamilyBonusClubPosUserPassword({ username, password }: { username: string; password: string }) {
    dispatch<any>(
      FamilyBonusClubUserActions.updateUserPassword({
        company_id: company.company_id,
        username,
        password,
        completion: () => {
          updateUserPasswordDialogHandler.close();
          loadUsers();
          dispatch<any>(showSuccessMessage(intl.get("family_bonus_club.pos_users.success_update_password")));
        },
      }),
    );
  }

  async function downloadInstructionPdf({ username }: { username: string }) {
    try {
      const res = await axios.put(
        `/v2/api/b2b/private/companies/${company.company_id}/family-bonus-club-users/pdf`,
        { username },
        { responseType: "blob" },
      );

      const dispositionHeader: string = res.headers["Content-Disposition"];
      let filename: string;
      if (dispositionHeader != null) {
        const fileIndex = dispositionHeader.indexOf("filename=");
        filename = dispositionHeader.substring(fileIndex + 9);
      } else {
        filename = `Anmeldung-FBC-Einloesung_${company.family_bonuscard.partner_id}-${username}`;
      }

      const newBlob = new Blob([res.data], { type: "application/pdf" });

      const nav = window.navigator as any;

      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob, filename);
        return;
      }

      const data = URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = data;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      if (link.parentNode != null) {
        link.parentNode.removeChild(link);
      }
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    } catch (error) {}
  }

  return (
    <>
      <PageTracker />

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item sx={AppStyles.headerLeft} flexGrow={1}>
          <HeaderLabel>{intl.get("family_bonus_club.pos_users.title")}</HeaderLabel>
        </Grid>

        <Grid item sx={AppStyles.headerMid}>
          <Stack direction={"row"} gap={"22px"}></Stack>
        </Grid>
        <Grid item sx={AppStyles.headerRight}>
          {!showFamilyBonusClubOnboarding && <DefaultButton sx={AppStyles.buttonPlaceholder} />}
          {!showFamilyBonusClubOnboarding && (
            <DefaultButton
              color={"secondary"}
              size={"large"}
              onClick={() => {
                createNewUser();
              }}
            >
              <AddIcon />
              {intl.get("family_bonus_club.pos_users.button_add")}
            </DefaultButton>
          )}
        </Grid>
      </Grid>

      <Stack sx={{ padding: 2 }} gap={2}>
        {showFamilyBonusClubOnboarding && (
          <CallToActionHeaderBanner
            title={intl.get("family_bonus_club.onboarding_card.title")}
            text={intl.get("family_bonus_club.onboarding_card.text")}
            actionTitle={intl.get("family_bonus_club.onboarding_card.call_to_action")}
            imageType={OnboardingImageType.familyBonusCardOnboarding}
            onAction={() => {
              joinFamilyBonusClub();
            }}
          />
        )}
      </Stack>

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item sx={AppStyles.headerLeft} flexGrow={1}>
          <Typography
            variant="h6"
            dangerouslySetInnerHTML={{
              __html: intl.get("family_bonus_club.pos_users.fbc_partner_id_header", {
                partner_id: company.family_bonuscard.partner_id,
              }),
            }}
          ></Typography>
          <br />
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: intl.get("family_bonus_club.pos_users.fbc_redemption_app_link", {
                redemption_app_url: process.env.REACT_APP_FBC_REDEMPTION_URL,
              }),
            }}
          />
        </Grid>
      </Grid>

      <Paper sx={AppStyles.innerContentPaper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none" sx={AppStyles.tableCell}>
                {intl.get("family_bonus_club.pos_users.header_username")}
              </TableCell>
              <TableCell padding="none" sx={AppStyles.tableCell}>
                {intl.get("family_bonus_club.pos_users.header_display_name")}
              </TableCell>
              <TableCell align={"right"} />
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => {
              return (
                <TableRow key={user.username}>
                  <TableCell padding="none" component="th" scope="row" sx={AppStyles.tableCellNotSelectable}>
                    {`${company.family_bonuscard.partner_id}-${user.username}`}
                  </TableCell>
                  {asNonEmptyString(user.display_name) !== null && (
                    <TableCell padding="none" sx={AppStyles.tableCellNotSelectable}>
                      {user.display_name}
                      <IconButton
                        style={{ marginLeft: 8 }}
                        color={"primary"}
                        size={"large"}
                        onClick={() => {
                          updateUserDisplayNameDialogHandler.open(user);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  )}
                  {asNonEmptyString(user.display_name) === null && (
                    <TableCell padding="none" sx={AppStyles.tableCellNotSelectable} style={{ fontStyle: "italic" }}>
                      {intl.get("family_bonus_club.pos_users.display_name_empty")}
                      <IconButton
                        style={{ marginLeft: 8 }}
                        color={"primary"}
                        size={"large"}
                        onClick={() => {
                          updateUserDisplayNameDialogHandler.open(user);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  )}

                  <TableCell align={"right"} padding="none" sx={AppStyles.tableCell}>
                    {user.status === FbcPosUserStatus.active && (
                      <>
                        <DefaultButton
                          color={"primary"}
                          size={"large"}
                          onClick={() => {
                            downloadInstructionPdf({ username: user.username });
                          }}
                        >
                          <PrintIcon sx={AppStyles.buttonLeftIcon} />
                          {intl.get("family_bonus_club.pos_users.action_print_instructions")}
                        </DefaultButton>
                        <LinkButton
                          onClick={() => {
                            updateUserPasswordDialogHandler.open(user);
                          }}
                          style={{ marginLeft: 16 }}
                          title={intl.get("family_bonus_club.pos_users.action_edit_password")}
                        ></LinkButton>
                        <IconButton
                          color={"error"}
                          size={"large"}
                          onClick={() => {
                            deleteUserHandler.open(user);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

      <DialogDeleteFamilyBonusClubPosUserConfirmation
        handler={deleteUserHandler}
        onConfirm={(user) => {
          deleteFamilyBonusClubPosUser({ username: user.username });
        }}
      />

      <DialogCreateFamilyBonusClubPosUser
        handler={newUserDialogHandler}
        onConfirm={(createRequest) => {
          createNewFamilyBonusClubPosUser({ request: createRequest });
        }}
      />

      <DialogUpdateFamilyBonusPosUserDisplayName
        handler={updateUserDisplayNameDialogHandler}
        onConfirm={(username, display_name) => {
          updateFamilyBonusClubPosUserDisplayName({ username, display_name });
        }}
      />
      <DialogUpdateFamilyBonusPosUserPassword
        handler={updateUserPasswordDialogHandler}
        onConfirm={(username, password) => {
          updateFamilyBonusClubPosUserPassword({ username, password });
        }}
      />

      <DialogJoinFamilyBonusClub
        handler={joinFamilyBonusClubDialogHandler}
        onFinishJoining={() => {
          joinFamilyBonusClubDialogHandler.close();
        }}
        onCancel={() => {
          joinFamilyBonusClubDialogHandler.close();
        }}
      />
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    companyState: state.companyState,
  };
}

function mapDispatchToProps() {
  return {};
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(FamilyBonusClubUsersPage);
