import { useEffect, useState } from "react";
import useLogger from "./useLogger";
import useApi from "./useApi";

type ErrorResponseBody = any;

export interface BackendLoader<T> {
  loading: boolean;
  error?: ErrorResponseBody;
  data?: T;
  reload: () => void;
}

export default function useBackendLoader<ResponseType, MappedResponseType = ResponseType>(
  url: string,
  resultMapper: (result: ResponseType) => MappedResponseType = (result) => result as any as MappedResponseType,
  logTag = "loader",
  enabled = true,
): BackendLoader<MappedResponseType> {
  const logger = useLogger(logTag);

  const api = useApi(logTag);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorResponseBody>();
  const [data, setData] = useState<MappedResponseType | undefined>();
  const [reloadFlag, setReloadFlag] = useState(false);

  const fetchData = async () => {
    setLoading(true);

    try {
      const res = await api.get<ResponseType>(url);
      if (res.ok) {
        setError(undefined);
        setData(resultMapper(res.body));
      } else {
        setError(res.error);
        setData(undefined);
        logger.warn("error", res.error);
      }
    } catch (e) {
      setError(e);
      setData(undefined);
      logger.warn("error", e);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, reloadFlag]);

  const reload = () => {
    if (loading) {
      return;
    }
    setReloadFlag(!reloadFlag);
  };

  return {
    loading,
    error,
    data,
    reload,
  };
}
