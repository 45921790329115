import { Dispatch } from "redux";
import { ActionType } from "../model/actions";

export const confirmErrorDialog = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.CONFIRM_ERROR_DIALOG });
};

export const confirmSuccessMessage = () => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.CONFIRM_SUCCESS_MESSAGE });
};

export const showSuccessMessage = (message: string) => async (dispatch: Dispatch) => {
  dispatch({ type: ActionType.SHOW_SUCCESS_MESSAGE, payload: { message } });
};
