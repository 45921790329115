export enum ActionType {
  FETCH_USER,
  AUTH_SUCCESS,
  AUTH_ERROR,
  AUTH_PROGRESS,

  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  SIGNUP_PROGRESS,

  UPDATE_PROFILE,

  LOGOUT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_PROGRESS,

  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_PROGRESS,

  GET_USER_PROFILE,

  FETCH_COMPANIES,
  UPDATE_COMPANIES_SEARCH_CRITERIA,
  FETCH_SALESPARTNERS,
  SELECT_COMPANY,
  FETCH_COMPANY,
  DELETE_COMPANY,
  CLOSE_COMPANY,

  FETCH_PAYMENTPLANS,
  DELETE_PAYMENTPLAN,
  RESET_PAYMENTPLAN_FORM,
  CLOSE_PAYMENTPLANS,
  EDIT_PAYMENTPLAN,
  CREATE_PAYMENTPLAN,
  UPDATE_PAYMENTPLAN_FORM_FIELD,
  SAVE_PAYMENTPLAN,

  FETCH_RESELLER_PROFILES,
  SELECT_RESELLER_PROFILES,
  RESET_RESELLER_PROFILE_FORM,
  CLOSE_RESELLER_PROFILES,
  EDIT_RESELLER_PROFILE,
  CREATE_RESELLER_PROFILE,
  UPDATE_RESELLER_PROFILE_FORM_FIELD,
  SAVE_RESELLER_PROFILE,

  FETCH_EARNINGS,
  SELECT_EARNINGS_ENTRY,
  CLOSE_EARNINGS,

  EDIT_COMPANY,
  CREATE_COMPANY,
  RESET_COMPANY_FORM,
  UPDATE_COMPANY_FORM_FIELD,
  SAVE_COMPANY,
  FETCH_COMPANY_USERS,

  FETCH_ADMIN_USERS,

  RESET_USER_FORM,
  CREATE_USER,
  EDIT_USER,
  UPDATE_USER_FORM_FIELD,
  CHANGE_COMPANY_USER_ROLE,
  CHANGE_ADMIN_USER_ROLE,
  CHANGE_PASSWORD,
  SAVE_USER,

  RESEND_INVITATION,

  REMOVE_USER_FROM_COMPANY,
  DELETE_ADMIN_USER,

  FETCH_COMPANY_EVENTS,
  FETCH_COMPANY_EVENTS_FBC_OFFER_STATUS,
  FETCH_COMPANY_UPGRADE_SLOT_INFO,
  UPDATE_COMPANY_SLOT_INFO,
  RESET_EVENT_FORM,
  UPDATE_EVENT_FORM_FIELD,
  VALIDATE_EVENT_FORM,
  CREATE_EVENT,
  EDIT_EVENT,
  SAVE_EVENT,
  UPDATE_EVENT_STATUS,
  UPDATE_EVENT_STATUS_BULK,
  DELETE_EVENT,
  EVENT_REVIEW,
  FETCH_INBOX_EVENTS,
  FETCH_FAMILY_BONUS_CLUB_INBOX_EVENTS,
  REVIEWED_FAMILY_BONUS_CLUB_OFFER,

  FETCH_COMPANY_MEDIA_ITEMS,
  DELETE_COMPANY_MEDIA_ITEM,
  UPLOAD_COMPANY_MEDIA_ITEM,
  UPDATE_COMPANY_MEDIA_ITEM,

  TOGGLE_EVENT_CATEGORY,
  ADD_TAG,
  REMOVE_TAG,
  ADD_EVENT_DEDICATED_TIME,
  REMOVE_EVENT_DEDICATED_TIME,
  ADD_EVENT_OPENING_HOUR,
  UPDATE_EVENT_OPENING_HOUR,
  REMOVE_EVENT_OPENING_HOUR,
  ADD_EVENT_MEDIA_ITEM,
  REMOVE_EVENT_MEDIA_ITEM,

  ADD_COMPANY_ADDRESS_AS_LOCATION,
  ADD_LOCATION,
  ADD_SHARED_LOCATION,
  REMOVE_LOCATION,
  UPDATE_LOCATION,

  SET_COMPANY_CONTACT_AS_EVENT_CONTACT,
  SET_COMPANY_CONTACT_PERSON_AS_EVENT_CONTACT_PERSON,

  ADD_EVENT_AVAILABILITY_RANGE,
  REMOVE_EVENT_AVAILABILITY_RANGE,
  UPDATE_AVAILABILITY_RANGE,

  CONFIRM_ERROR_DIALOG,
  CONFIRM_SUCCESS_MESSAGE,
  SHOW_SUCCESS_MESSAGE,

  PAGE_LOADING,
  PAGE_LOADING_ERROR,
  PAGE_LOADED,
  PAGE_SAVING_ERROR,
  PAGE_SAVING,
  PAGE_SAVED,
  RESET_PAGE_STATE,

  UPDATE_SEARCH_QUERY,
  SEARCH_START,
  SEARCH_FAILED,
  SEARCH_FINISHED,

  FETCH_COMPANY_INVOICES,
  FETCH_COMPANY_PAYMENT_METHOD,
  UPDATE_COMPANY_PAYMENT_METHOD,
  CHARGE_COMPANY_OVERDUE_INVOICES,

  FETCH_SEOPAGES,
  SELECT_SEOPAGES,
  DELETE_SEOPAGE,
  RESET_SEOPAGE_FORM,
  CLOSE_SEOPAGES,
  TOGGLE_SEOPAGE_CATEGORY,
  EDIT_SEOPAGE,
  CREATE_SEOPAGE,
  UPDATE_SEOPAGE_FORM_FIELD,
  SAVE_SEOPAGE,

  FETCH_PAYMENT_PRODUCT_INFORMATION,
  FETCH_AVAILABLE_PAYMENT_PRODUCTS,

  RESET_HERO_FORM,
  LOAD_HERO_FORM,
  BOOK_HERO,
  RESET_AVAILABLE_HERO_SLOTS,
  LOAD_AVAILABLE_HERO_SLOTS,

  EDIT_SHARED_LOCATION,
  FETCH_SHARED_LOCATIONS,
  RESET_SHARED_LOCATION_FORM,
  UPDATE_SHARED_LOCATION_FORM_FIELD,
  SAVE_SHARED_LOCATION,
  DELETE_SHARED_LOCATION,

  RESET_ACTIVITY_LOGS,
  LOAD_ACTIVITY_LOGS,
  CREATE_ACTIVITY_LOG_ENTRY,

  FETCH_TRANSFER_COMPANIES,

  FETCH_PLUGIN_API_KEYS,
  UPDATE_PLUGIN_API_KEY,

  ADD_COMPANY_CUSTOM_CATEGORY,
  REMOVE_COMPANY_CUSTOM_CATEGORY,

  RESET_COMPANY_REPORTING_RESULT,
  FAILED_COMPANY_REPORTING_RESULT,
  LOADED_COMPANY_REPORTING_RESULT,

  FETCH_GLOBAL_INVOICES,
}

export interface Action<T> {
  type: ActionType;
  payload: T;
}
