import * as intl from "react-intl-universal";
import createReducer from "./createReducer";
import { cloneDefaultState } from "../util/util_extensions";
import { Action, ActionType } from "../model/actions";
import { ErrorDialogState } from "../model/errorDialogState";

export const errorDialogState = createReducer(cloneDefaultState({ error: null, successMessage: null }), {
  [ActionType.CONFIRM_ERROR_DIALOG](state: ErrorDialogState) {
    return { ...state, error: null };
  },
  [ActionType.CONFIRM_SUCCESS_MESSAGE](state: ErrorDialogState) {
    return { ...state, successMessage: null };
  },
  [ActionType.SHOW_SUCCESS_MESSAGE](state: ErrorDialogState, action: Action<any>) {
    if (action.payload && action.payload.message) {
      return { ...state, successMessage: action.payload.message };
    }
    return state;
  },
  [ActionType.UPDATE_EVENT_STATUS](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.failed) {
      return { ...state, error: action.payload.error };
    }
    return state;
  },
  [ActionType.FETCH_USER](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.has_rejected_events) {
      return { ...state, error: intl.get("event_rejected_hint_dialog.message") };
    }
    return state;
  },
  [ActionType.DELETE_COMPANY_MEDIA_ITEM](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.failed) {
      return { ...state, error: action.payload.error };
    }
    return state;
  },
  [ActionType.UPLOAD_COMPANY_MEDIA_ITEM](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.failed) {
      return { ...state, error: action.payload.error };
    }
    return state;
  },
  [ActionType.UPDATE_COMPANY_MEDIA_ITEM](state: ErrorDialogState, action: Action<any>) {
    if (action.payload.failed) {
      return { ...state, error: action.payload.error };
    }
    return state;
  },
});
