import { BatchItem, FILE_STATES, useBatchFinishListener, useBatchStartListener } from "@rpldy/uploady";
import useLogger from "../../hooks/useLogger";

export interface FbcUploadyListenerProps<T> {
  onBatchError?: (error: any) => void;
  onBatchStart?: (batch: any) => void;
  onBatchSuccess?: (responses: T[], files: BatchItem[]) => void;
  onBatchCancelled?: () => void;
}

export default function FbcUploadyListener({
  onBatchSuccess,
  onBatchStart,
  onBatchError,
  onBatchCancelled,
}: FbcUploadyListenerProps<any>) {
  const logger = useLogger(`uploadyListener`);

  useBatchStartListener((batch) => {
    logger.log(`batch start`);
    if (onBatchStart) {
      onBatchStart(batch);
    }
  });

  useBatchFinishListener((batch) => {
    const cancelItem = batch.items.find((item) => item.state === FILE_STATES.CANCELLED);
    const errorItem = batch.items.find((item) => item.state === FILE_STATES.ERROR);

    if (cancelItem) {
      logger.log("batch cancelled. Unsupported filetype?");
      onBatchCancelled();
    } else if (!errorItem) {
      logger.log(`batch success`, batch);
      if (onBatchSuccess) {
        onBatchSuccess(
          batch.items.map((item) => item.uploadResponse.data),
          batch.items,
        );
      }
    } else {
      const error = errorItem.uploadResponse?.data;
      logger.log(`batch error`, error);
      if (onBatchError) {
        onBatchError(error);
      }
    }
  });

  return <div />;
}
