import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import FbcUploady from "../../components/upload/fbcUploady";
import HandlerDialog from "../../components/dialog/handlerDialog";
import FbcClickableUploadyDropZone from "../../components/upload/fbcUploadyDropZone";
import { DialogHandler } from "../../components/dialog/useDialogHandler";
import intl from "react-intl-universal";
import useSelectedCompanyId from "../../hooks/useSelectedCompanyId";
import FbcUploadyListener from "../../components/upload/fbcUploadyListener";
import MimeTypeInterceptor from "../../components/upload/mimeTypeInterceptor";
import { useState } from "react";
import useLogger from "../../hooks/useLogger";
import useDialogReset from "../../components/dialog/useDialogReset";
import axios from "axios";

export interface DialogFbcOfferCodesCsvUploadPayload {
  offerId: string;
}

const acceptedMimeTypes =
  "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain";

export default function DialogFbcOfferCodesCsvUpload({
  handler,
  onSuccess,
}: {
  handler: DialogHandler<DialogFbcOfferCodesCsvUploadPayload>;
  onSuccess: () => void;
}) {
  const logger = useLogger("DialogFbcOfferCodesCsvUpload");
  const companyId = useSelectedCompanyId();

  const [error, setError] = useState<string | undefined>(undefined);

  useDialogReset(handler, () => {
    setError(undefined);
  });

  const handleStart = () => {
    setError(undefined);
  };

  const handleSuccess = () => {
    onSuccess();
    handler.close();
  };

  const handleError = (e: any) => {
    logger.warn({ error: e });
    setError(intl.get("error.generic"));
  };

  const handleCancel = () => {
    setError(intl.get(`family_bonus_club.offer_online_codes.file_upload.error_unsupported_filetype`));
  };

  return (
    <FbcUploady
      accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain"
      multiple={false}
      paramName="file"
      destination={{
        url: `${process.env.REACT_APP_API_URL}/v2/api/b2b/private/companies/${companyId}/family-bonus-club-offers/${handler.payload?.offerId}/digital_offer_codes_upload`,
        method: `POST`,
        headers: {
          Authorization: axios.defaults.headers.common.Authorization,
        },
      }}
    >
      <FbcUploadyListener
        onBatchError={handleError}
        onBatchSuccess={handleSuccess}
        onBatchCancelled={handleCancel}
        onBatchStart={handleStart}
      />
      <MimeTypeInterceptor acceptedTypes={acceptedMimeTypes.split(",")} />
      <HandlerDialog handler={handler}>
        <DialogTitle>{intl.get(`family_bonus_club.offer_online_codes.file_upload.title`)}</DialogTitle>
        <DialogContent sx={{ minWidth: 600 }}>
          <Typography
            variant={"body1"}
            sx={{ marginTop: 1, marginBottom: 1 }}
            dangerouslySetInnerHTML={{
              __html: intl.get("family_bonus_club.offer_online_codes.file_upload.hint_file_format"),
            }}
          />

          <FbcClickableUploadyDropZone />
          {error && (
            <Typography color={"error"} variant={"body2"} sx={{ marginTop: 1 }}>
              {error}
            </Typography>
          )}
          {!error && (
            <Typography variant={"body2"} sx={{ marginTop: 1 }}>
              {intl.get("family_bonus_club.offer_online_codes.file_upload.hint_supported_filetypes")}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handler.close}>{intl.get(`generic.btn_cancel`)}</Button>
        </DialogActions>
      </HandlerDialog>
    </FbcUploady>
  );
}
