import { ErrorDialogState } from "../model/errorDialogState";
import * as ErrorDialogActions from "../actions/errorDialog";
import * as React from "react";
import { Alert, Snackbar } from "@mui/material";
import { RootState } from "../reducers";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

export interface Props {
  errorDialogState: ErrorDialogState;
  actions: typeof ErrorDialogActions;
  title?: string;
}

class SuccessMessageSnackBar extends React.Component<Props> {
  confirmSuccessMessage() {
    this.props.actions.confirmSuccessMessage();
  }

  render() {
    if (this.props.errorDialogState.successMessage !== null) {
      return (
        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={this.props.errorDialogState.successMessage !== null}
          autoHideDuration={4000}
          onClose={() => {
            this.confirmSuccessMessage();
          }}
        >
          <Alert severity="success">{this.props.errorDialogState.successMessage}</Alert>
        </Snackbar>
      );
    }
    return null;
  }
}

function mapStateToProps(state: RootState) {
  return {
    errorDialogState: state.errorDialogState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(ErrorDialogActions as any, dispatch),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SuccessMessageSnackBar);
