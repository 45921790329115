import createReducer from "./createReducer";
import { Action, ActionType } from "../model/actions";
import { EventState } from "../model/eventState";
import { BCEvent } from "../model/event";

export const eventState = createReducer(
  {
    paging: {
      page_size: 10,
      current_page: 1,
    },
    events: [],
    eventUpgradeSlots: undefined,
    is_missing_fbc_pos_users: false,
    fbc_offer_titles_missing_online_codes: [],
  },
  {
    [ActionType.FETCH_COMPANY_EVENTS](state: EventState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          events: action.payload.events,
          is_missing_fbc_pos_users: action.payload.is_missing_fbc_pos_users,
          fbc_offer_titles_missing_online_codes: action.payload.fbc_offer_titles_missing_online_codes,
          paging: action.payload.paging,
          eventUpgradeSlots: action.payload.eventUpgradeSlots,
        };
      }
      return { ...state };
    },
    [ActionType.FETCH_COMPANY_EVENTS_FBC_OFFER_STATUS](state: EventState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          is_missing_fbc_pos_users: action.payload.is_missing_fbc_pos_users,
          fbc_offer_titles_missing_online_codes: action.payload.fbc_offer_titles_missing_online_codes,
        };
      }
      return { ...state };
    },
    [ActionType.FETCH_COMPANY_UPGRADE_SLOT_INFO](state: EventState, action: Action<any>) {
      if (action.payload.data === true) {
        return {
          ...state,
          eventUpgradeSlots: action.payload.eventUpgradeSlots,
        };
      }
      return { ...state };
    },
    [ActionType.EVENT_REVIEW](state: EventState, action: Action<any>) {
      const newState = { ...state };
      const eventIndex = newState.events.findIndex((value) => value.event_id === action.payload.event_id);
      newState.events.splice(eventIndex, 1);
      return newState;
    },
    [ActionType.UPDATE_EVENT_STATUS](state: EventState, action: Action<any>) {
      if (action.payload.data === true) {
        const newState = { ...state };
        const { event, slotInfo } = action.payload;
        const eventIndex = newState.events.findIndex((value) => value.event_id === event.event_id);
        newState.events[eventIndex] = event;
        newState.eventUpgradeSlots = slotInfo;
        return newState;
      }
      return state;
    },
    [ActionType.UPDATE_EVENT_STATUS_BULK](state: EventState, action: Action<any>) {
      if (action.payload.data === true) {
        const newState = { ...state };
        const { events, slotInfo } = action.payload;

        (events as BCEvent[]).map((event) => {
          const eventIndex = newState.events.findIndex((value) => value.event_id === event.event_id);
          newState.events[eventIndex] = event;
        });

        newState.eventUpgradeSlots = slotInfo;
        return newState;
      }
      return state;
    },
    [ActionType.UPDATE_COMPANY_SLOT_INFO](state: EventState, action: Action<any>) {
      if (action.payload.slotInfo) {
        const newState = { ...state };
        const { slotInfo } = action.payload;
        newState.eventUpgradeSlots = slotInfo;
        return newState;
      }
      return state;
    },
    [ActionType.DELETE_EVENT](state: EventState, action: Action<any>) {
      if (action.payload.data === true) {
        const newState = { ...state };
        const eventIndex = newState.events.findIndex((value) => value.event_id === action.payload.event_id);
        newState.events.splice(eventIndex, 1);
        return newState;
      }
      return state;
    },
    [ActionType.CLOSE_COMPANY]() {
      return {
        paging: {
          page_size: 10,
          current_page: 1,
        },
        events: [],
        is_missing_fbc_pos_users: false,
        fbc_offer_titles_missing_online_codes: [],
      };
    },
  },
);
