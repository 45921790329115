import { DialogHandler } from "../../components/dialog/useDialogHandler";
import { FbcPosUser } from "../../model/fbc/fbc.pos.user";
import HandlerDialog from "../../components/dialog/handlerDialog";
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { useState } from "react";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";

export default function DialogUpdateFamilyBonusPosUserDisplayName({
  handler,
  onConfirm,
}: {
  handler: DialogHandler<FbcPosUser>;
  onConfirm: (username: string, display_name: string) => void;
}) {
  return (
    <HandlerDialog handler={handler}>
      {handler.payload && (
        <Content
          user={handler.payload}
          onConfirm={({ display_name }) => {
            onConfirm(handler.payload.username, display_name);
          }}
          onCancel={handler.close}
        />
      )}
    </HandlerDialog>
  );
}

function Content({
  user,
  onConfirm,
  onCancel,
}: {
  user: FbcPosUser;
  onConfirm: ({ display_name }: { display_name: string }) => void;
  onCancel: () => void;
}) {
  const [displayName, setDisplayName] = useState<string>(user.display_name ?? "");

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {intl.get("family_bonus_club.pos_users.update_display_name.title")}
      </DialogTitle>
      <DialogContent>
        <Grid item container direction="column">
          <TextFieldWithLabel
            required={false}
            label={intl.get("family_bonus_club.pos_users.update_display_name.label_display_name")}
            id={"display_name"}
            margin="normal"
            color={"secondary"}
            onChange={(event) => {
              setDisplayName(event.target.value);
            }}
            value={displayName}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
          variant={"outlined"}
          color={"defaultTextColor"}
        >
          {intl.get("family_bonus_club.pos_users.update_display_name.action_cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm({ display_name: displayName });
          }}
          color="secondary"
          autoFocus
        >
          {intl.get("family_bonus_club.pos_users.update_display_name.action_update")}
        </Button>
      </DialogActions>
    </>
  );
}
