import { DialogHandler } from "../../components/dialog/useDialogHandler";
import HandlerDialog from "../../components/dialog/handlerDialog";
import * as React from "react";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import { FbcPosUser } from "../../model/fbc/fbc.pos.user";

export default function DialogDeleteFamilyBonusClubPosUserConfirmation({
  handler,
  onConfirm,
}: {
  handler: DialogHandler<FbcPosUser>;
  onConfirm: (user: FbcPosUser) => void;
}) {
  return (
    <HandlerDialog handler={handler}>
      {handler.payload && (
        <Content
          onConfirm={() => onConfirm(handler.payload)}
          onCancel={handler.close}
          username={handler.payload.username}
          displayName={handler.payload.display_name}
        />
      )}
    </HandlerDialog>
  );
}

function Content({
  onConfirm,
  onCancel,
  username,
  displayName,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  username: string;
  displayName?: string;
}) {
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {intl.get("family_bonus_club.pos_users.delete_confirmation.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          dangerouslySetInnerHTML={{
            __html: displayName
              ? intl.get("family_bonus_club.pos_users.delete_confirmation.message_with_display_name", {
                  username,
                  displayName,
                })
              : intl.get("family_bonus_club.pos_users.delete_confirmation.message", {
                  username,
                }),
          }}
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          {intl.get("family_bonus_club.pos_users.delete_confirmation.action_cancel")}
        </Button>
        <Button onClick={onConfirm} color="error" autoFocus>
          {intl.get("family_bonus_club.pos_users.delete_confirmation.action_confirm")}
        </Button>
      </DialogActions>
    </>
  );
}
