import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Grid, Snackbar, Stack, useTheme } from "@mui/material";
import * as intl from "react-intl-universal";
import { EventFormState } from "../../model/eventFormState";
import * as EventActions from "../../actions/event";
import * as EventFormActions from "../../actions/eventForm";
import { RootState } from "../../reducers";
import EventEditForm from "../components/forms/EventEditForm";
import { BackButton } from "../../components/Buttons";
import { PageTracker } from "../../util/pageTracker";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import AppStyles from "../../styles/appStyles";
import useFormLeavePrompt from "../../hooks/useFormLeavePrompt";
import useUser from "../../hooks/useUser";
import useSelectedCompany from "../../hooks/useSelectedCompany";
import EventReviewForm from "../components/eventedit/EventReviewForm";
import { BCEventStatus } from "../../model/event";
import EventEditHeaderActions from "../components/eventedit/EventEditHeaderActions";
import { eventHasActiveOrPendingFamilyBonusClubOffers } from "../../util/util_extensions";

function EventEditPageFamilyBonusClubOfferWarning() {
  const theme = useTheme();

  return (
    <>
      <Grid sx={AppStyles.warningContainer(theme)}>
        <Grid sx={AppStyles.warningSubContainer}>
          <Grid item container direction={"column"} sx={AppStyles.warningTextLeft(theme)}>
            <span dangerouslySetInnerHTML={{ __html: intl.get("family_bonus_club.event_edit_warning") }}></span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default function EventEditPage() {
  const dispatch = useDispatch();

  const user = useUser();
  const company = useSelectedCompany();

  const eventFormState = useSelector<RootState, EventFormState>((rootState) => {
    return rootState.eventFormState;
  });

  const [eventStatus, setEventStatus] = useState<BCEventStatus>(eventFormState.event.status || BCEventStatus.PUBLISHED);
  const [rejectionDescription, setRejectionDescription] = useState("");

  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [showSentToReviewSuccess, setShowSentToReviewSuccess] = useState(false);

  useEffect(() => {
    if (eventFormState.event.status) {
      setEventStatus(eventFormState.event.status);
    }
  }, [eventFormState.event.status]);

  const navigate = useNavigate();
  const params = useParams();

  useFormLeavePrompt(eventFormState.dirty && !eventFormState.readonly);

  function closePage(): void {
    navigate(-1);
  }

  useEffect(() => {
    if (params == null) {
      dispatch<any>(EventFormActions.createNewEvent(company.company_id, company));
    } else {
      const companyId = params.company_id;
      const eventId = params.event_id;

      if (companyId && eventId) {
        if (company.permissions.indexOf("EDIT_COMPANY_EVENTS") === -1) {
          dispatch<any>(EventActions.startEditingEvent(companyId, eventId, true));
        } else {
          dispatch<any>(EventActions.startEditingEvent(companyId, eventId, false));
        }
      } else {
        dispatch<any>(EventFormActions.createNewEvent(companyId, company));
      }
    }
  }, []);

  function saveChanges(): void {
    const event = eventFormState.event;
    if (eventStatus) {
      event.status = eventStatus;
    }
    dispatch<any>(
      EventActions.saveEvent(company.company_id, eventFormState.event_id, event, () => {
        setShowSaveSuccess(true);
      }),
    );
  }

  function handleSubmitForReview(): void {
    const event = eventFormState.event;
    event.status = BCEventStatus.READY_FOR_REVIEW;
    dispatch<any>(
      EventActions.saveEvent(company.company_id, eventFormState.event_id, event, () => {
        setShowSentToReviewSuccess(true);
      }),
    );
  }

  if (eventFormState.closePage) {
    dispatch<any>(EventFormActions.resetEventForm());
    closePage();
    return <div />;
  }

  return (
    <>
      <PageTracker />

      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showSaveSuccess}
        autoHideDuration={2000}
        onClose={() => {
          setShowSaveSuccess(false);
        }}
      >
        <Alert severity="success">{intl.get("company_events.event_saved_confirmation")}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showSentToReviewSuccess}
        autoHideDuration={2000}
        onClose={() => {
          setShowSentToReviewSuccess(false);
        }}
      >
        <Alert severity="success">{intl.get("company_events.event_sent_to_review_confirmation")}</Alert>
      </Snackbar>

      <EventEditForm>
        <Stack>
          <Stack direction="row" sx={[AppStyles.actionHeaderLeft, { marginRight: 2 }]} gap={"24px"} flexWrap={"wrap"}>
            <BackButton
              sx={{ marginRight: "auto" }}
              title={eventFormState.event_id ? intl.get("event_form.title_edit") : intl.get("event_form.title_create")}
              onClick={() => {
                closePage();
              }}
            />

            <EventEditHeaderActions
              onSave={saveChanges}
              eventStatus={eventStatus}
              onEventStatusChange={setEventStatus}
              rejectionDescription={rejectionDescription}
              onSubmitForReview={handleSubmitForReview}
              companyId={company?.company_id}
            />
          </Stack>

          {user.hasPermission("REVIEW_COMPANY_EVENTS") && (
            <EventReviewForm
              rejectionDescription={rejectionDescription}
              onRejectionDescriptionChanged={setRejectionDescription}
            />
          )}
          {eventHasActiveOrPendingFamilyBonusClubOffers({ event: eventFormState.event }) && (
            <EventEditPageFamilyBonusClubOfferWarning />
          )}
        </Stack>
      </EventEditForm>
    </>
  );
}
