import useDialogHandler, { DialogHandler } from "../../components/dialog/useDialogHandler";
import { FbcOffer } from "../../model/fbc/fbc.offer";
import HandlerDialog from "../../components/dialog/handlerDialog";
import useBackendLoader from "../../hooks/useBackendLoader";
import useDialogReset from "../../components/dialog/useDialogReset";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import AppStyles from "../../styles/appStyles";
import { DefaultButton } from "../../components/Buttons";
import * as FamilyBonusClubActions from "../../actions/family.bonus.club.actions";
import { useDispatch } from "react-redux";
import axios from "axios";
import DialogDeleteUnusedFamilyBonusClubOfferOnlineCodesConfirmation from "./DialogDeleteUnusedFamilyBonusClubOfferOnlineCodesConfirmation";
import DialogFbcOfferCodesCsvUpload, { DialogFbcOfferCodesCsvUploadPayload } from "./DialogFbcOfferCodesCsvUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";

interface FbcOfferOnlineCodeDto {
  code: string;
  status: string;
}

interface FbcOfferOnlineCodesBackendResponse {
  codes: FbcOfferOnlineCodeDto[];
}

export default function DialogManageFamilyBonusClubOfferOnlineCodes({
  handler,
  onChangedCodes,
}: {
  handler: DialogHandler<FbcOffer>;
  onChangedCodes: () => void;
}) {
  const companyId = handler.payload?.company_id;
  const offerId = handler.payload?.offer_id;

  const dispatch = useDispatch();

  const uploadDialogHandler = useDialogHandler<DialogFbcOfferCodesCsvUploadPayload>();

  const loader = useBackendLoader<FbcOfferOnlineCodesBackendResponse, FbcOfferOnlineCodeDto[]>(
    `/v2/api/b2b/private/companies/${companyId}/family-bonus-club-offers/${offerId}/digital_offer_codes`,
    (result) => result.codes,
    "FbcOfferOfferOnlineCodes",
  );

  useDialogReset(handler, () => {
    loader.reload();
  });

  const theme = useTheme();

  const offerTitle = handler.payload?.draft_offer
    ? handler.payload?.draft_offer.title
    : handler.payload?.published_offer.title;

  const deleteUnusedFamilyBonusClubOfferOnlineCodesHandler = useDialogHandler<FbcOffer>("deleteUnusedOfferOnlineCodes");

  async function downloadOnlineCodesCsv() {
    try {
      const res = await axios.get(
        `/v2/api/b2b/private/companies/${companyId}/family-bonus-club-offers/${offerId}/digital_offer_codes/csv`,
        { responseType: "blob" },
      );

      const dispositionHeader: string = res.headers["Content-Disposition"];
      let filename: string;
      if (dispositionHeader != null) {
        const fileIndex = dispositionHeader.indexOf("filename=");
        filename = dispositionHeader.substring(fileIndex + 9);
      } else {
        filename = `Online-Codes_FBC-Angebot_${offerTitle}`;
      }

      const newBlob = new Blob([res.data], { type: "text/csv" });

      const nav = window.navigator as any;

      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob, filename);
        return;
      }

      const data = URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = data;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      if (link.parentNode != null) {
        link.parentNode.removeChild(link);
      }
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    } catch (error) {}
  }

  async function deleteUnusedCodes() {
    dispatch<any>(
      FamilyBonusClubActions.deleteUnusedFamilyBonusClubOfferOnlineCodes({
        company_id: companyId,
        offer_id: offerId,
        completion: () => {
          loader.reload();
          onChangedCodes();
        },
      }),
    );
  }

  return (
    <>
      <HandlerDialog handler={handler} fullWidth={true} maxWidth="lg">
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item flexGrow={1}>
              <Box
                sx={() => ({
                  color: "#3c3c3b",
                  fontWeight: 800,
                  fontFamily: theme.headerFontFamily,
                  fontSize: theme.fontSizes.mediumFont,
                })}
              >
                {intl.get("family_bonus_club.offer_online_codes.title", {
                  offer_title: offerTitle,
                })}
              </Box>
            </Grid>

            <Grid item>
              <Stack direction={"row"} gap={"22px"}></Stack>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  handler.close();
                }}
                color="primary"
              >
                {intl.get("family_bonus_club.offer_online_codes.close_action")}
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <>
            <Table>
              {!loader.loading && loader.data && (
                <TableHead>
                  <TableRow>
                    <TableCell sx={AppStyles.tableCellNotSelectable}>
                      {intl.get("family_bonus_club.offer_online_codes.list_header_codes")}
                    </TableCell>
                    <TableCell sx={AppStyles.tableCellNotSelectable} align="right">
                      {intl.get("family_bonus_club.offer_online_codes.list_header_status")}
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {loader.loading && (
                  <TableRow key={"fbc_online_codes_loading"}>
                    <TableCell colSpan={2} align={"center"}>
                      {intl.get("family_bonus_club.offer_online_codes.loading")}
                    </TableCell>
                  </TableRow>
                )}
                {!loader.loading && (
                  <>
                    {loader.data && loader.data.length > 0 && (
                      <>
                        {loader.data.map((value) => {
                          return (
                            <TableRow key={`fbc_online_codes_${value.code}`}>
                              <TableCell>{value.code}</TableCell>
                              <TableCell align={"right"}>
                                {intl.get(`family_bonus_club.offer_online_codes.status_${value.status}`)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </>
                    )}
                    {loader.data && loader.data.length === 0 && (
                      <TableRow key={"fbc_online_codes_missing"}>
                        <TableCell colSpan={2} align={"center"}>
                          {intl.get("family_bonus_club.offer_online_codes.no_codes_uploaded")}
                        </TableCell>
                      </TableRow>
                    )}
                    {loader.error && (
                      <TableRow key={"fbc_online_codes_error"}>
                        <TableCell colSpan={2} align={"center"} style={{ color: theme.palette.error.main }}>
                          {intl.get("family_bonus_club.offer_online_codes.error")}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </>
        </DialogContent>
        <DialogActions>
          <DefaultButton
            color={"error"}
            size={"large"}
            startIcon={<DeleteSweepIcon />}
            onClick={() => {
              deleteUnusedFamilyBonusClubOfferOnlineCodesHandler.open(handler.payload);
            }}
          >
            {intl.get("family_bonus_club.offer_online_codes.action_purge_unused_codes")}
          </DefaultButton>
          <DefaultButton
            color={"primary"}
            size={"large"}
            style={{ marginLeft: 10 }}
            startIcon={<DownloadIcon />}
            onClick={() => {
              downloadOnlineCodesCsv();
            }}
          >
            {intl.get("family_bonus_club.offer_online_codes.action_download_status_csv")}
          </DefaultButton>
          <DefaultButton
            color={"secondary"}
            size={"large"}
            style={{ marginLeft: 10 }}
            startIcon={<UploadFileIcon />}
            onClick={() => {
              uploadDialogHandler.open({ offerId });
            }}
          >
            {intl.get("family_bonus_club.offer_online_codes.action_add_codes")}
          </DefaultButton>
        </DialogActions>
      </HandlerDialog>
      <DialogDeleteUnusedFamilyBonusClubOfferOnlineCodesConfirmation
        handler={deleteUnusedFamilyBonusClubOfferOnlineCodesHandler}
        onConfirm={() => {
          deleteUnusedCodes();
          deleteUnusedFamilyBonusClubOfferOnlineCodesHandler.close();
        }}
      />
      <DialogFbcOfferCodesCsvUpload
        handler={uploadDialogHandler}
        onSuccess={() => {
          loader.reload();
          onChangedCodes();
        }}
      />
    </>
  );
}
