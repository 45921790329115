import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import { AuthenticationState } from "../model/authenticationState";
import FCRoot from "../components/Root";
import ErrorDialog from "../components/ErrorDialog";
import PageStateView from "../components/PageStateView";
import { Outlet } from "react-router-dom";
import * as React from "react";
import { styled } from "@mui/material/styles";
import useAxiosSetup from "./useAxiosSetup";
import usePageStateSetup from "./usePageStateSetup";
import SuccessMessageSnackBar from "../components/SuccessMessageSnackBar";

const Root = styled("main")(({}) => ({
  overflow: "scroll",
  flexGrow: 1,
  paddingTop: 120,
  marginLeft: "auto",
  marginRight: "auto",
  backgroundColor: "#f2f2f2",
}));

const StyledRoot = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  zIndex: 1,
  minWidth: "320px",
  backgroundColor: "#f2f2f2",
  fontFamily: theme.fontFamily,
  color: theme.bodyTextColor,
}));

export default function RouterAppOutletShell() {
  const authenticationState = useSelector<RootState, AuthenticationState>((rootState) => rootState.authenticationState);
  const needsToFinishAuthenticating = () => {
    return authenticationState.user === null && localStorage.getItem("b2b-access-token") !== null;
  };

  useAxiosSetup();
  usePageStateSetup();

  return (
    <StyledRoot>
      <div>
        <FCRoot />
        <ErrorDialog />
        <SuccessMessageSnackBar />
        {!needsToFinishAuthenticating() && (
          <Root
            style={{
              paddingTop: authenticationState.user && authenticationState.user.status === "UNCONFIRMED" ? 150 : 120,
              minHeight: "100vh",
              overflow: "scroll",
              flexGrow: 1,
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "#f2f2f2",
            }}
            sx={(theme) => ({ paddingLeft: { xs: 0, sm: theme.dimensions.drawerWidth } })}
          >
            <PageStateView />
            <Outlet />
          </Root>
        )}
      </div>
    </StyledRoot>
  );
}
