import { DialogHandler } from "../../components/dialog/useDialogHandler";
import { FbcPosUser } from "../../model/fbc/fbc.pos.user";
import HandlerDialog from "../../components/dialog/handlerDialog";
import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { useState } from "react";
import TextFieldWithLabel from "../../components/TextFieldWithLabel";
import { asNonEmptyString } from "../../util/util_extensions";

export default function DialogUpdateFamilyBonusPosUserPassword({
  handler,
  onConfirm,
}: {
  handler: DialogHandler<FbcPosUser>;
  onConfirm: (username: string, password: string) => void;
}) {
  return (
    <HandlerDialog handler={handler}>
      {handler.payload && (
        <Content
          onConfirm={({ password }) => {
            onConfirm(handler.payload.username, password);
          }}
          onCancel={handler.close}
        />
      )}
    </HandlerDialog>
  );
}

function Content({
  onConfirm,
  onCancel,
}: {
  onConfirm: ({ password }: { password: string }) => void;
  onCancel: () => void;
}) {
  const [password, setPassword] = useState<string>("");
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");

  const savePossible = () => {
    return asNonEmptyString(password) && password === passwordRepeat;
  };
  return (
    <>
      <DialogTitle id="alert-dialog-title">{intl.get("family_bonus_club.pos_users.update_password.title")}</DialogTitle>
      <DialogContent>
        <Grid item container direction="column">
          <TextFieldWithLabel
            type="password"
            required={true}
            label={intl.get("family_bonus_club.pos_users.update_password.label_password")}
            id={"password"}
            margin="normal"
            color={"secondary"}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            value={password}
          />
          <TextFieldWithLabel
            type="password"
            required={true}
            label={intl.get("family_bonus_club.pos_users.update_password.label_password_repeat")}
            id={"password_repeat"}
            margin="normal"
            color={"secondary"}
            onChange={(event) => {
              setPasswordRepeat(event.target.value);
            }}
            value={passwordRepeat}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
          variant={"outlined"}
          color={"defaultTextColor"}
        >
          {intl.get("family_bonus_club.pos_users.update_password.action_cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm({ password });
          }}
          color="secondary"
          autoFocus
          disabled={!savePossible()}
        >
          {intl.get("family_bonus_club.pos_users.update_password.action_update")}
        </Button>
      </DialogActions>
    </>
  );
}
