import UploadDropZone from "@rpldy/upload-drop-zone";
import { Box, Typography, styled } from "@mui/material";
import React, { forwardRef, useCallback, useState } from "react";
import { asUploadButton } from "@rpldy/upload-button";
import { useBatchFinishListener, useBatchProgressListener, useBatchStartListener } from "@rpldy/uploady";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import intl from "react-intl-universal";

const StyledDropZone = styled(UploadDropZone)(() => ({
  borderRadius: 12,
  border: `1px solid black`,
  color: `black`,
  boxSizing: `border-box`,
  "&.active": {
    color: `black`,
    border: `1px dashed black`,
  },
}));

interface ClickableProps {
  onClick: (event: any) => void;
}

interface ClickableDropZoneHandle {
  onClick: (event: any) => void;
}

const MyClickableDropZone = forwardRef<ClickableDropZoneHandle, ClickableProps>((props, ref) => {
  const { onClick, destination, ...buttonProps } = props as any;

  const onZoneClick = useCallback(
    (event: any) => {
      if (onClick) {
        onClick(event);
      }
    },
    [onClick],
  );

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [itemCount, setItemCount] = useState(0);

  useBatchStartListener((batch) => {
    setUploading(true);
    setItemCount(batch.items.length);
  });

  useBatchFinishListener(() => setUploading(false));

  useBatchProgressListener((batch) => {
    setProgress(Math.round(batch.completed));
  });

  if (uploading) {
    return (
      <Box
        sx={() => ({
          minHeight: `260px`,
          width: `100%`,
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
          borderRadius: `12px`,
          border: `1px solid black`,
          color: `black`,
        })}
      >
        <UploadFileIcon />
        <Typography sx={{ marginLeft: 0.7 }}>
          {uploading
            ? intl.get(`family_bonus_club.offer_online_codes.file_upload.message_progress`, {
                progress,
                count: itemCount,
              })
            : intl.get(`family_bonus_club.-offer_online_codes.file_upload.label_dropzone`)}
        </Typography>
      </Box>
    );
  }

  return (
    <StyledDropZone
      ref={ref}
      destination={destination}
      sx={{
        minHeight: `260px`,
        width: `100%`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        cursor: `pointer`,
      }}
      {...buttonProps}
      enableOnContains={true}
      onDragOverClassName="active"
      extraProps={{ onClick: onZoneClick }}
      shouldHandleDrag={true}
    >
      <UploadFileIcon />
      {intl.get(`family_bonus_club.offer_online_codes.file_upload.label_dropzone`)}
    </StyledDropZone>
  );
});

MyClickableDropZone.displayName = `DropZone`;

const FbcClickableUploadyDropZone = asUploadButton<ClickableDropZoneHandle, ClickableProps>(MyClickableDropZone);

export default FbcClickableUploadyDropZone;
