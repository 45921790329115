import { Theme } from "@mui/material";

const AppStyles = {
  centerPaper: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 2,
    paddingRight: 3,
    paddingLeft: 3,
    paddingBottom: 3,
  },

  centerCardLayout: (theme: Theme) => ({
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: 3,
    marginRight: 3,
    [theme.breakpoints.up(840)]: {
      width: "840px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  }),

  appForm: {
    width: "100%", // Fix IE 11 issue.
    marginTop: 1,
  },

  detailContent: {
    width: "100%", // Fix IE 11 issue.
  },

  detailContentWithPadding: {
    width: "100%", // Fix IE 11 issue.
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 2,
    paddingBottom: 2,
  },

  detailForm: {
    width: "100%", // Fix IE 11 issue.
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
  },

  detailFormWithoutVerticalPadding: {
    width: "100%", // Fix IE 11 issue.
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: 5,
  },

  detailFormWithoutBottomPadding: {
    width: "100%", // Fix IE 11 issue.
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: 2,
  },

  detailFormWithoutSidePadding: {
    width: "100%", // Fix IE 11 issue.
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 5,
  },

  detailFormRow: (theme: Theme) => ({
    borderRight: "1px solid rgba(224, 224, 224, 0.5)",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  }),

  detailFormRowNoBorder: {
    borderRight: "none",
    padding: "12px",
  },

  detailFormRowNoBorderNoTopPadding: {
    borderRight: "none",
    padding: "12px",
    paddingTop: 0,
  },

  detailFormRowNoBorderSmall: {
    borderRight: "none",
    padding: "4px",
  },

  detailFormRowNoBorderNoTopPaddingSmall: {
    borderRight: "none",
    padding: "4px",
    paddingTop: 0,
  },

  detailFormGroup: {
    height: "100%",
  },

  detailFormControlLabel: {
    height: "100%",
  },

  detailFormRowWithSideBorder: (theme: Theme) => ({
    padding: "12px",
    borderRight: "1px solid rgba(224, 224, 224, 0.5)",
    [theme.breakpoints.up("md")]: {
      borderRight: "none",
    },
  }),

  section: {
    paddingTop: "10px",
    paddingBottom: "10px",
  },

  innerContentRoot: (theme: Theme) => ({
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
    maxWidth: theme.maxWidth,
  }),

  innerContentPaper: {
    marginLeft: 2,
    marginRight: 2,
    marginTop: 2,
    marginBottom: 2,
  },

  innerContentRootWithoutLimit: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10px",
  },

  innerContentWithHeaderMargins: {
    marginLeft: "24px",
    marginRight: "24px",
    marginTop: 2,
    marginBottom: 2,
  },

  innerContentPaperError: (theme: Theme) => ({
    marginLeft: 2,
    marginRight: 2,
    marginTop: 2,
    marginBottom: 2,
    border: `1px solid ${theme.colors.red}`,
  }),

  innerContentPaperHint: (theme: Theme) => ({
    marginLeft: 2,
    marginRight: 2,
    marginTop: 2,
    marginBottom: 2,
    border: `1px solid ${theme.palette.primary.main}`,
  }),

  noRightPadding: {
    paddingRight: "0px !important",
    marginRight: "0px !important",
  },

  buttonPlaceholder: {
    margin: 1,
    opacity: 0,
    cursor: "initial",
  },

  buttonLeftIcon: {
    marginRight: 1,
  },

  buttonRightIcon: {
    marginLeft: 1,
  },

  headerRight: {
    marginRight: "15px",
    marginTop: "10px",
  },

  headerMid: {
    marginRight: "22px",
    marginLeft: "22px",
    marginTop: "10px",
  },

  headerLeft: {
    marginLeft: "24px",
    marginTop: "10px",
  },

  actionHeaderLeft: {
    marginLeft: "15px",
    marginTop: "10px",
  },

  smallTableHeader: {
    height: "35px",
  },

  tableCellNotSelectable: (theme: Theme) => ({
    cursor: "default",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
  }),

  tableCellNotSelectableGreyed: (theme: Theme) => ({
    cursor: "default",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
    color: theme.palette.secondary.main,
  }),

  tableCellNotSelectableRed: (theme: Theme) => ({
    cursor: "default",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
    color: theme.colors.red,
  }),

  tableCell: (theme: Theme) => ({
    cursor: "pointer",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
  }),

  tableCellRed: (theme: Theme) => ({
    cursor: "pointer",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    color: theme.colors.red,
  }),

  tableCellGreen: (theme: Theme) => ({
    cursor: "pointer",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    color: theme.palette.secondary.main,
  }),

  tableCellWarning: (theme: Theme) => ({
    cursor: "pointer",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    color: "#ff9800",
  }),

  tableHeaderCell: (theme: Theme) => ({
    cursor: "pointer",
    fontSize: theme.fontSizes.smallFont,
    paddingLeft: "16px",
    paddingRight: "16px",
    height: "50px",
  }),

  formActionBar: {
    backgroundColor: "#F5F5F5",
  },

  alignCenter: {
    alignItems: "center",
  },

  alignSelfCenter: {
    alignSelf: "center",
  },

  toggleContainer: (theme: Theme) => ({
    height: "56px",
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    background: theme.palette.background.default,
  }),

  formInputContainer: (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    background: theme.palette.background.default,
  }),

  dateRangeInput: {
    color: "black",
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    "&:disabled": {
      color: "black",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    },
  },

  autoMarginTop: {
    marginTop: "auto",
  },

  productInfoCardTitle: (theme: Theme) => ({
    color: theme.palette.primary.main,
    fontSize: theme.fontSizes.biggerFont,
    textAlign: "center",
    fontWeight: 700,
    wordWrap: "break-word",
    wordBreak: "break-word",
    marginTop: "20px",
  }),

  productInfoCardText: (theme: Theme) => ({
    textAlign: "center",
    fontSize: theme.fontSizes.mediumFont,
    marginTop: "16px",
    wordWrap: "break-word",
    wordBreak: "break-word",
  }),
  warningContainer: (theme: Theme) => ({
    color: "white",
    background: "rgb(237, 108, 2)",
    fontSize: theme.fontSizes.mediumFont,
    padding: 2,
    height: "100%",
    marginLeft: "24px",
    marginRight: "24px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
  }),
  warningSubContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  warningTextCenter: (theme: Theme) => ({
    color: "#FFFFFF",
    background: "rgb(237, 108, 2)",
    fontSize: theme.fontSizes.mediumFont,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  }),
  warningTextLeft: (theme: Theme) => ({
    color: "#FFFFFF",
    background: "rgb(237, 108, 2)",
    fontSize: theme.fontSizes.mediumFont,
    height: "100%",
    display: "flex",
  }),
};

export default AppStyles;
