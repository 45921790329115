import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import intl from "react-intl-universal";
import * as React from "react";
import { DialogHandler } from "../../components/dialog/useDialogHandler";
import HandlerDialog from "../../components/dialog/handlerDialog";

export default function DialogReminderAboutFbcOfferOnlineCodes({
  handler,
  onConfirm,
}: {
  handler: DialogHandler<void>;
  onConfirm: () => void;
}) {
  return (
    <HandlerDialog handler={handler} fullWidth={true}>
      <DialogTitle id="alert-dialog-title">
        {intl.get("family_bonus_club.offer_online_codes.offer_edit_notification.title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          dangerouslySetInnerHTML={{
            __html: intl.get("family_bonus_club.offer_online_codes.offer_edit_notification.text"),
          }}
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onConfirm();
          }}
          color="primary"
        >
          {intl.get("family_bonus_club.offer_online_codes.offer_edit_notification.action_confirm")}
        </Button>
      </DialogActions>
    </HandlerDialog>
  );
}
