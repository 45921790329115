import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormGroup,
  Grid,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import { bindActionCreators } from "redux";
import * as intl from "react-intl-universal";
import { connect } from "react-redux";
import { theme } from "../../../../theme";
import { DefaultButton, LinkButton } from "../../../../components/Buttons";
import EventOpeningHourEdit from "./EventOpeningHourEdit";
import * as EventFormActions from "../../../../actions/eventForm";
import { SectionLabel } from "../../../../components/SectionLabel";
import { RootState } from "../../../../reducers";
import { EventFormState } from "../../../../model/eventFormState";
import { BCAvailabilityRangeType, BCEventAvailabilityRange, BCEventRangeOpeningHours } from "../../../../model/event";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { EventEditRadioField } from "./fields/EventEditRadioField";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/DateRangePicker";
import moment from "moment";
import "moment/locale/de";
import "rsuite/dist/rsuite.min.css";
import AppStyles from "../../../../styles/appStyles";

export interface BaseProps {
  eventFormState: EventFormState;
}

export interface Props extends BaseProps {
  eventFormActions: typeof EventFormActions;
}

class EventRecurringRuleEdit extends React.Component<Props> {
  state = {
    editingAvailabilityRange: null,
    deleteAvailabilityRangeConfirmationVisible: false,
  };

  renderOpeningHoursForRange(range: BCEventAvailabilityRange, openingHours: BCEventRangeOpeningHours[]) {
    const disableControls = this.props.eventFormState.readonly;

    const hourMap = openingHours.map((value, index) => {
      return (
        <TableRow key={value.event_range_opening_hours_id}>
          <EventOpeningHourEdit
            disabled={disableControls}
            openingHour={value}
            onRemove={() => {
              this.props.eventFormActions.removeAvailabilityRangeOpeningHour(range, value);
            }}
            onChange={(newValue) => {
              this.props.eventFormActions.updateAvailabilityRangeOpeningHour(range, index, newValue);
            }}
          />
        </TableRow>
      );
    });

    return (
      <>
        <Table>
          <TableBody>{hourMap}</TableBody>
        </Table>
        &nbsp;
        {this.props.eventFormState.readonly === false && (
          <LinkButton
            onClick={(event: any) => {
              event.stopPropagation();
              this.props.eventFormActions.addAvailabilityRangeOpeningHour(range);
            }}
            title={intl.get("event_form.button_add_opening_hour")}
          />
        )}
      </>
    );
  }

  renderDateInputAndEditForAvailabilityRange(range: BCEventAvailabilityRange) {
    if (this.props.eventFormState.event.event_range_type === BCAvailabilityRangeType.AllYear) {
      return null;
    }

    const displayFormat =
      this.props.eventFormState.event.event_range_type === BCAvailabilityRangeType.Seasonal ? "DD. MM" : "DD. MM. yyyy";
    let value: DateRange = null;
    if (moment(range.start_date) && moment(range.end_date)) {
      value = [moment(range.start_date).toDate(), moment(range.end_date).toDate()];
    }
    return (
      <>
        <Grid item xs={4} container direction={"column"} sx={AppStyles.detailFormRowWithSideBorder}>
          <Grid item container direction="column">
            <SectionLabel>
              {intl.get(
                this.props.eventFormState.event.event_range_type === BCAvailabilityRangeType.Seasonal
                  ? "event_form.recurring_event.date_seasonal"
                  : "event_form.recurring_event.date_individual",
              )}
            </SectionLabel>
            <DateRangePicker
              locale={{
                formattedMonthPattern: "MMMM",
                formattedDayPattern: "dd. MMMM",
                dateLocale: "de",
              }}
              isoWeek={true}
              readOnly={this.props.eventFormState.readonly}
              cleanable={false}
              format={"dd.MM.yyyy"}
              ranges={[]}
              onOk={(selectedDateRange) => {
                range.start_date = selectedDateRange[0];
                range.end_date = selectedDateRange[1];
                this.setState({});
                this.props.eventFormActions.updateEventAvailabilityRangesRange();
              }}
              onChange={(selectedDateRange) => {
                if (selectedDateRange) {
                  range.start_date = selectedDateRange[0];
                  range.end_date = selectedDateRange[1];
                  this.setState({});
                  this.props.eventFormActions.updateEventAvailabilityRangesRange();
                }
              }}
              renderValue={(selectedDateRange) => {
                let first = displayFormat;
                let second = displayFormat;
                if (selectedDateRange && selectedDateRange.length > 0) {
                  first = moment(selectedDateRange[0]).locale("de").format(displayFormat);
                }
                if (selectedDateRange && selectedDateRange.length > 1) {
                  second = moment(selectedDateRange[1]).locale("de").format(displayFormat);
                }
                return `${first} ~ ${second}`;
              }}
              value={value}
            />
          </Grid>
        </Grid>
        <Grid item xs={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}></Grid>

        <Grid item xs={4} container direction={"column"} sx={AppStyles.detailFormRowNoBorder} alignContent={"flex-end"}>
          {this.props.eventFormState.readonly === false &&
            this.props.eventFormState.event.event_range_type !== BCAvailabilityRangeType.AllYear && (
              <FormGroup sx={AppStyles.autoMarginTop}>
                <LinkButton
                  color={"error"}
                  style={{ marginTop: "auto" }}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    this.setState({
                      editingAvailabilityRange: range,
                      deleteAvailabilityRangeConfirmationVisible: true,
                    });
                  }}
                  title={"Gültigkeitsbereich entfernen"}
                />
              </FormGroup>
            )}
        </Grid>
      </>
    );
  }

  renderAvailabilityRange(range: BCEventAvailabilityRange) {
    return (
      <>
        <Grid container direction="row" sx={AppStyles.detailFormWithoutVerticalPadding}>
          {this.renderDateInputAndEditForAvailabilityRange(range)}

          <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
            {this.renderOpeningHoursForRange(range, range.opening_hours)}
          </Grid>
          <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
            <Divider />
          </Grid>
        </Grid>
      </>
    );
  }

  renderDeleteRangeConfirmationDialog() {
    if (this.state.deleteAvailabilityRangeConfirmationVisible === false) {
      return null;
    }

    return (
      <Dialog
        open={true}
        onClose={() => {
          this.setState({ editingAvailabilityRange: null, deleteAvailabilityRangeConfirmationVisible: false });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Gültigkeitsbereich entfernen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Möchten Sie den Gültigkeitsbereich für das Freizeitangebot wirklich entfernen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.setState({ editingAvailabilityRange: null, deleteAvailabilityRangeConfirmationVisible: false });
            }}
            color="primary"
          >
            Nein, abbrechen
          </Button>
          <Button
            onClick={() => {
              this.props.eventFormActions.removeAvailabilityRange(this.state.editingAvailabilityRange);
              this.setState({ editingAvailabilityRange: null, deleteAvailabilityRangeConfirmationVisible: false });
            }}
            color="error"
            autoFocus
          >
            Ja, entfernen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderAvailabilityRangeTypes() {
    return (
      <EventEditRadioField
        hideLabel={true}
        required={true}
        formState={this.props.eventFormState}
        formActions={this.props.eventFormActions}
        field={"event_range_type"}
        options={[
          BCAvailabilityRangeType.AllYear,
          BCAvailabilityRangeType.Seasonal,
          BCAvailabilityRangeType.Individual,
        ]}
      />
    );
  }

  render() {
    const ranges = this.props.eventFormState.event.event_availability_ranges?.map((value) =>
      this.renderAvailabilityRange(value),
    );

    return (
      <>
        {this.renderDeleteRangeConfirmationDialog()}

        <Grid container direction="row" sx={AppStyles.detailContentWithPadding}>
          <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
            <Typography component="h4" variant="h4" style={{ fontSize: theme.fontSizes.smallFont, fontWeight: 700 }}>
              {intl.get("event_form.recurring_event.description")}
            </Typography>
          </Grid>

          <Grid item xs={12} container direction={"column"} sx={AppStyles.detailFormRowNoBorder}>
            {this.renderAvailabilityRangeTypes()}
          </Grid>
          {ranges}

          {this.props.eventFormState.readonly === false &&
            this.props.eventFormState.event.event_range_type !== null &&
            this.props.eventFormState.event.event_range_type !== BCAvailabilityRangeType.AllYear && (
              <Grid container direction="column" sx={AppStyles.detailFormWithoutVerticalPadding}>
                <DefaultButton
                  onClick={(event: any) => {
                    event.stopPropagation();
                    this.props.eventFormActions.addAvailabilityRange();
                  }}
                >
                  <DateRangeIcon sx={AppStyles.buttonLeftIcon} />
                  Weiteren Gültigkeitsbereich hinzufügen
                </DefaultButton>
              </Grid>
            )}
        </Grid>
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    eventFormState: state.eventFormState,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    eventFormActions: bindActionCreators(EventFormActions as any, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventRecurringRuleEdit);
